import React from "react";
import {
  Button,
  Container,
  HStack,
  Heading,
  Image,
  Show,
  Text,
  VStack,
} from "@chakra-ui/react";

function Hero() {
  return (
    <Container
      size="md"
      pt={{ base: "0.5rem", md: "3.75rem" }}
      pr={{ xl: "0.25rem" }}
    >
      <HStack justify={{ base: "start", md: "center", xl: "space-between" }}>
        <VStack
          maxW={{ md: "28.125rem", xl: "100%" }}
          align={{ base: "start", md: "center", xl: "start" }}
          textAlign={{ base: "start", md: "center", xl: "start" }}
          spacing={{ base: "1.6rem", md: "1.65rem" }}
        >
          <Heading as="h1" size="3xl">
            Xaaza, s.r.o.
          </Heading>
          <Text color="muted" lineHeight="taller">
            <p>
				Datum vzniku:	14.9.2009
			</p>
			<p>
				Obchodní firma:	Xaaza, s.r.o.
			</p>
			<p>
				Adresa sídla:	Prachatice - Prachatice II, U Studánky 1299, PSČ 38301
			</p>
			<p>
				Identifikační číslo:	28110579
			</p>
			<p>
				Identifikátor datové schránky:	3bjxhde
			</p>
			<p>
				Z. kapitál:	200 000 Kč
			</p>
			<p>
				Spisová značka	C 17751/KSCB Krajský soud v Českých Budějovicích
			</p>
			<p></p>
			<p></p>
          </Text>
        </VStack>

        <Show above="xl">
          <Image src="assets/images/man-gde49afc12_640.jpg" alt="Hero image" />
        </Show>
      </HStack>
    </Container>
  );
}

export default Hero;
